import Vue from "vue";
import { capitalize } from "../tools/string";
import i18n from "@/i18n"

export const RESEARCH_JUSTIFICATIONS = {
  'insured' : {
    'code': '0001',
    'description': Vue.prototype.$capitalize(i18n.tc('model.leads_insureds_availability')),
  },
  'reparator' : {
    'code': '0002',
    'description': Vue.prototype.$capitalize(i18n.tc('model.leads_repairer_availability')),
  },
  'external_reparator' : {
    'code': '0003',
    'description': Vue.prototype.$capitalize(i18n.tc('model.leads_availability_repairer_external_entity')),
  }
};

export async function update(lead_number, payload) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_number}/research_report`, payload);

  return response.data;
}