<template>
  <v-card flat>
    <v-card-title>
      {{ $capitalize($tc('model.research_report_research_report_title')) }}
      <v-spacer />
      <ScopeProvider scope="leads.research_report.edit">
        <v-btn outlined color="primary" :loading="saving" @click="update">
          {{ $capitalize($tc('model.visit_report_save_button')) }}
        </v-btn>
      </ScopeProvider>
    </v-card-title>

    <ValidationObserver ref="research_report_form">
      <v-card-text>
        <ValidationProvider name="research_report.needs_research" v-slot="{ errors }">
          <v-select
            :label="$capitalize($tc('model.research_report_need_research'))"
            :items="[
              {
                value: true,
                text: $capitalize($tc('model.research_report_yes_item')),
              },
              {
                value: false,
                text: $capitalize($tc('model.research_report_no_item')),
              },
            ]"
            v-model="form.needs_research"
            :error-messages="errors"
            outlined
          ></v-select>
        </ValidationProvider>
        <v-row>
          <v-col>
            <ValidationProvider name="research_report.research_justification" v-slot="{ errors }">
              <v-select
                :items="list_research_justifications"
                v-model="form.research_justification"
                item-value="code"
                item-text="description"
                :label="$capitalize($tc('model.research_report_research_justification'))"
                :error-messages="errors"
                outlined
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <Timestamp
          v-model="form.research_begin_date"
          :date.sync="research_begin_date"
          :time.sync="research_begin_time"
          :label="__('Data de início', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="research_begin_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="research_begin_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>

        <Timestamp
          v-model="form.research_estimated_end_date"
          :date.sync="research_estimated_end_date"
          :time.sync="research_estimated_end_time"
          :label="__('Previsão de finalização', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="research_estimated_end_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="research_estimated_end_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>

        <Timestamp
          v-model="form.research_end_date"
          :date.sync="research_end_date"
          :time.sync="research_end_time"
          :label="__('Data de finalização', 'schedulings')"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="research_end_date"
                type="date"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="research_end_time"
                type="time"
                dense
                outlined
              ></v-text-field>  
            </v-col>
          </v-row>
        </Timestamp>

        <ValidationProvider :rules="form.needs_research ? 'required' : ''" name="research_report.research_extimated_price" v-slot="{ errors }">
          <MoneyInput
            v-model="form.research_extimated_price"
            :label="$capitalize($tc('model.research_report_estimated_value'))"
            :error-messages="errors"
            outlined
          />
        </ValidationProvider>
      </v-card-text>
    </ValidationObserver>
  </v-card>
</template>

<script>
import { RESEARCH_JUSTIFICATIONS, update } from "@/services/research_reports";

import MoneyInput from "@/components/MoneyInput";
import ScopeProvider from "@/components/ScopeProvider";
import Timestamp from "@/components/tools/Timestamp";
import i18n from '@/mixins/i18n';

export default {
  components: { MoneyInput, ScopeProvider, Timestamp },
  mixins: [i18n],
  data: function() {
    return {
      form: {},

      saving: false,

      research_begin_date: "",
      research_begin_time: "",
      research_estimated_end_date: "",
      research_estimated_end_time: "",
      research_end_date: "",
      research_end_time: "",
    };
  },
  computed: {
    lead() {
      return this.$store.getters.getLead;
    },
    list_research_justifications() {
      return Object.values(RESEARCH_JUSTIFICATIONS);
    }
  },
  methods: {
    async update() {
      const validation = await this.$refs.research_report_form.validate();
      if (!validation) return;

      this.saving = true;

      try {
        const response = await update(this.$route.params["lead_number"], {
          research_report: this.form
        });
        this.$store.commit('setLead', {
          ...this.lead,
          default_service: {
            ...this.lead.default_service,
            ...response
          }
        });
        this.$store.commit('sendMessage', {
          text: 'Relatório atualizado com sucesso',
          color: 'green'
        });
      } catch(e) {
        if (e?.response?.data?.errors) {
          this.$refs.research_report_form.setErrors(e?.response?.data.errors);
        }
        if (e?.response?.data?.message) {
          this.$store.commit('sendMessage', {
            text: e?.response?.data?.message,
            color: 'red'
          });
        }
      } finally {
        this.saving = false;      
      }
    },

    setDateAndTime(date, type) {
      if (date) {
        const date_parts = date.split(" ");

        this[type + '_date'] = date_parts[0],
        this[type + '_time'] = date_parts[1];
      }
    },

    loadForm() {
      this.form = {
        needs_research: this.lead.default_service.needs_research,
        research_begin_date: this.lead.default_service.research_begin_date,
        research_estimated_end_date: this.lead.default_service.research_estimated_end_date,
        research_end_date: this.lead.default_service.research_end_date,
        research_extimated_price: this.lead.default_service.research_extimated_price,
        research_justification: this.lead.default_service.research_justification == null ?
          RESEARCH_JUSTIFICATIONS['insured'].code :
          this.lead.default_service.research_justification
      };

      this.setDateAndTime(this.lead.default_service.research_begin_date, 'research_begin');
      this.setDateAndTime(this.lead.default_service.research_estimated_end_date, 'research_estimated_end');
      this.setDateAndTime(this.lead.default_service.research_end_date, 'research_end');
    },
  },
  created() {
    this.loadForm();
  }
}
</script>

<style>

</style>